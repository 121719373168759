.authModal {

  &-img {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    &.light-theme {
      filter: invert(1);
      .authModal-logo {
        filter: grayscale(1);
      }
    }
  }
  &-logo {
    width: 240px;
    height: auto;
  }
  &-body {
    min-height: 640px;
    background: var(--authModal-bg);
  }
  &-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &-header {
    margin-bottom: 65px;
  }

  &-link {
    color: #4797ee;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }

  &-checkbox {
    .authModal-text {
      text-align: left;
    }
  }
  &-btn {
    margin-top: 40px;
    &.login-btn {
      margin-bottom: 35px;
    }
  }
  &-text {
    color: #a4a4a4;
    text-align: center;
    font-size: 14px;
    line-height: 140%;
    a {
      color: #448fe0;
      text-decoration: none;
    }
    span {
      color: var(--classic-color);
    }
  }
  .link-style {
    color: #448fe0;
    text-decoration: none;
  }
  &-terms {
    margin-bottom: 36px;
  }
  &-forgot {
    margin-bottom: 30px;
    &-icon {
      width: 58px;
      height: auto;
      display: block;
      margin: 0 auto 17px;
    }
    &-text {
      max-width: 295px;
      width: 100%;
      text-align: center;
      color: var(--classic-color);
      margin: 0 auto;
    }
  }
  &-recovery {
		&-links {
			margin-top: 50px;
		}
  }
  &-steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 19px;
    margin-bottom: 35px;
    &-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--authModal-step-btn-bg);
      border: 1px solid #a4a4a4;
      border-radius: 5px;
      color: #a4a4a4;
      font-size: 20px;
      height: 40px;
      font-weight: 700;
      &:not(.cursor-not-allowed) {
        background: linear-gradient(
            360deg,
            #ff8a00 -95%,
            rgba(255, 138, 0, 0) 50%
          ),
          #f1dc02;
        border-color: transparent;
        color: #000;
      }
    }
  }
  &-phone {
    display: grid;
    grid-template-columns: 1.2fr 2fr;
    grid-gap: 15px;
  }
  &-recoveryText {
    font-size: 12px;
    color: var(--classic-color);
    margin-top: 20px;
    text-align: center;
  }
  &-recoveryBtn {
    margin-top: 30px;
  }
}

// for country select
.suffix-select {
  .ant-select-arrow {
    left: 11px;
    right: initial;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .ant-select-selector {
    padding-left: 41px !important;
  }
  .ant-select-selection-search-input {
    padding-left: 30px !important;
  }
}




@media screen and (max-width: 800px) {
  .authModal-body {
    min-height: initial;
  }
  .authModal-row {
    grid-template-columns: 1fr 2fr;
  }
  .authModal-logo {
    width: 85%;
  }
}

@media screen and (max-width: 650px) {
  .authModal {
    height: 85%;
    margin-top: 30px;
    background: var(--authModal-bg);
  }
  .authModal .ant-modal-content {
    box-shadow: none;
  }
  .authModal-row {
    grid-template-columns: 1fr;
  }
  .authModal-img {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .authModal-header {
    margin-bottom: 40px;
  }
  .authModal-btn {
    margin: 30px 0;
  }
  .authModal-forgot-icon {
    width: 40px;
  }
  .authModal-steps {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 450px) {
  .authModal-steps-btn {
    font-size: 17px;
  }
}

@media screen and (max-width: 400px) {
  .authModal-recoveryText {
    text-align: left;
  }
  .authModal-steps {
    grid-gap: 15px;
  }
  .authModal-phone {
    grid-gap: 10px;
  }
}

@media screen and (max-width: 350px) {
  .authModal .ant-picker-suffix {
    margin-right: 10px;
    width: 15px;
  }

  .suffix-select {
    .ant-select-arrow {
      left: 11px;
      right: initial;
      width: 15px;
      height: 15px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .ant-select-selector {
      padding-left: 36px !important;
    }
    .ant-select-selection-search-input {
      padding-left: 25px !important;
    }
  }
}
