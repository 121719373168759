.datePicker {
  position: relative;
  background-color: var(--input-background);
  border-radius: 5px;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;

  &__button {
    height: 40px;
    width: 100%;
    padding: 4px 11px;
    text-align: left;
    border: 1px solid #3B4047;
    border-radius: 5px;
    transition: .3s border-color ease;

    &:hover {
      border-color: white;
    }
  }

  &__icon {
    color: white;
    margin-right: 20px;
  }
}

.rdp {
  position: absolute;
  top: 43px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #2F2F2F;
  margin: 0;
  --rdp-cell-size: 40px; /* Size of the day cells. */
  --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
  --rdp-accent-color: none; /* Accent color for the background of selected days. */
  --rdp-background-color: #ccc; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */
}
