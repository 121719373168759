.dark {
  --theme-color: #fff000;
	--switch-bg: linear-gradient(0deg, #ff8a00 -70%, rgba(255, 138, 0, 0) 81.11%), #fcdf01;
	--header-bg: linear-gradient( 180deg, rgba(255, 255, 255, 0.2) -130.71%, rgba(255, 255, 255, 0) 100% ), #171717;
  --header-search-bg: #292929;
  --classic-color: #fff;
  --dropdow-bg: #202020;
  --sidebar-block-bg: #212121;
  --sidebar-bg: linear-gradient( 264.18deg, rgba(255, 255, 255, 0.2) -944.87%, rgba(255, 255, 255, 0) 108.12% ), #171717;
  --sidebar-link-color: #fff;
  --sidebar-link-img-hover: #fbec01;
  --banner-bg-content: rgba(0, 0, 0, 0.8);
  --gslider-title-color: #fff;
  --gslider-title-border: #fbe700;
  --gslider-title-background: linear-gradient( 90deg, #000000 0%, rgba(0, 0, 0, 0) 49.15% );
  --footer-bg: #171717;
  --footer-bg-shadow: 0px -21px 38px rgba(0, 0, 0, 0.25);
  --footer-slogan-color: #fff000;
  --navbar-mobile-btn: #1c1c1c;
  --navbar-profile-btn-bg: #1c1c1c;
  --navbar-profile-btn-shadow: inset 0px -3px 7px rgba(255, 255, 255, 0.15);
  --navbar-profile-balance: #1c1c1c;
  --navbar-search-border: #2b2b2b;
  --sidebar-profile-name: #fff;
  --sidebar-profile-balance: #fff000;
  --modal-bg: #212121;
  --game-card-num-shadow: #fbec02;
  --mailModal-header-bg: linear-gradient( 270deg, rgba(255, 255, 255, 0.0001) 0%, rgba(255, 255, 255, 0.0819493) 100% );
  --input-border: #a4a4a4;
  --input-background: rgba(70, 70, 70, 0.38);
  --authModal-bg: #171717;
  --authModal-step-btn-bg: #292929;
  --login-input-active-autofill: 0 0 0px 500px #25272c inset;
	--side-menu-img: invert(2%) sepia(316%) saturate(1075%) hue-rotate(27deg) brightness(115%) contrast(207%);
	--casino-win-bg: #1c1c1d;
	--casino-category-img: invert(2%) sepia(316%) saturate(1075%) hue-rotate(27deg) brightness(115%) contrast(207%);
	--casino-nav-img: invert(2%) sepia(316%) saturate(1075%) hue-rotate(27deg) brightness(115%) contrast(207%);
  --yellow-line: radial-gradient(
    65px 100px ellipse at 50% 80%,
    #FBEC00,
    #FBEC00,
    #262a32
  );
	--support-block-bg: #171717;
	--cabinet-nav-bg: linear-gradient(269.85deg, #1B1B1B 10.79%, rgba(27, 27, 27, 0) 50.13%), #212121;
	--payment-card-bg: #212121;
	--cabinet-form-bg: #171717;
	--green-text: #7CF102;
	--ant-dropdown-bg: #292929;
	--table-border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	--banner-bg-slide-m: rgba(0, 0, 0, .7);
  --side-menu-selected: rgba(255, 255, 255, 0.08);
  --footer-text-clr: #67696f;
	--button-outline-shadow-hover: 0px 0px 12px rgba(251, 236, 0, 0.65), inset 0px 0px 16px #FBEC00, inset 0px -6px 5px rgba(0, 0, 0, 0.25);
	--button-filled-shadow-hover: 0px 0px 12px rgba(251, 236, 0, 0.65);
}


.light {
  --theme-color: #ffc001;
  --switch-bg: linear-gradient(0deg, #ff8a00 -70%, rgba(255, 138, 0, 0) 81.11%), #fcdf01;
	--header-bg: linear-gradient( 23.34deg, rgba(0, 0, 0, 0.05) -60.37%, rgba(0, 0, 0, 0) 85.39% ), #ffffff;
  --header-search-bg: #e8e8e8;
  --classic-color: #000;
  --dropdow-bg: #fff;
  --sidebar-block-bg: #fff;
  --sidebar-bg: linear-gradient( 264.18deg, rgba(255, 255, 255, 0.2) -944.87%, rgba(255, 255, 255, 0) 108.12% ), #ffffff;
  --sidebar-link-color: #717171;
  --sidebar-link-img-hover: linear-gradient( 0.97deg, #ff8a00 -44.02%, rgba(255, 138, 0, 0) 82.14% ), #fbec01;
  --banner-bg-content: rgba(255, 255, 255, 0.8);
  --gslider-title-color: #ffc001;
  --gslider-title-border: linear-gradient( 320.06deg, #fbe700 -41.04%, rgba(251, 231, 0, 0) 179.98% ), #ff8a00;
  --gslider-title-background: linear-gradient( 90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100% );
  --footer-bg: #fff;
  --footer-bg-shadow: 0px -8px 20px rgba(0, 0, 0, 0.1);
  --footer-slogan-color: #000;
  --navbar-mobile-btn: #707070;
  --navbar-profile-btn-bg: linear-gradient( 180deg, #a4a4a4 -61.67%, rgba(164, 164, 164, 0) 100% ), #ffffff;
  --navbar-profile-btn-shadow: inset 0px -1px 3px rgba(0, 0, 0, 0.25);
  --navbar-profile-balance: #ffc001;
  --navbar-search-border: #ababab;
  --sidebar-profile-name: #000;
  --sidebar-profile-balance: #ffc001;
  --modal-bg: #ffffff;
  --game-card-num-shadow: #ffc001;
  --mailModal-header-bg: linear-gradient( 270deg, #e8e8e8 0%, rgba(255, 255, 255, 0.0819493) 100% );
  --input-border: rgba(0, 0, 0, 0.3);
  --input-background: #e8e8e8;
  --authModal-bg: #fff;
  --authModal-step-btn-bg: #f4f4f4;
  --login-input-active-autofill: 0 0 0px 500px #e8e8e8 inset;
	--side-menu-img: invert(43%) sepia(62%) saturate(4520%) hue-rotate(188deg) brightness(104%) contrast(105%);
	--casino-win-bg: #FBFBFB;
	--casino-category-img: invert(73%) sepia(76%) saturate(2348%) hue-rotate(358deg) brightness(108%) contrast(105%);
	--casino-nav-img: invert(25%) sepia(76%) saturate(2348%) hue-rotate(372deg) brightness(108%) contrast(105%);
  --yellow-line: radial-gradient(
    65px 100px ellipse at 50% 80%,
    #FBEC00,
    #FBEC00,
    #ffffff
  );
	--support-block-bg: #E8E8E8;
	--cabinet-nav-bg: #fff;
	--cabinet-form-bg: #fff;
	--green-text: #5EB900;
	--payment-card-bg: #f3f3f3;
	--ant-dropdown-bg: #ffffff;
	--table-border-bottom: 1px solid #dfdfdf;
	--banner-bg-slide-m: rgba(255, 255, 255, .7);
  --side-menu-selected: rgba(0, 0, 0, 0.05);
  --footer-text-clr: #a3a3a3;
	--button-outline-shadow-hover: inset 0px 0px 12px rgba(255, 192, 0, 1);
	--button-filled-shadow-hover: 0px 0px 12px rgba(255, 192, 0, 0.65);
	--header-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

}

/* tablet */
@media screen and (max-width: 1320px) {
  .dark {
    --header-bg: linear-gradient(
        360deg,
        #ff8a00 -101.67%,
        rgba(255, 138, 0, 0) 100%
      ),
      #fbec00;
    --header-mobile-theme-fill-bg: #1c1c1c;
    --header-mobile-theme-color: #fff000;
    --header-mobile-theme-outline: #000;
    --switch-bg: #1c1c1c;
    --header-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
  .light {
    --header-mobile-theme-fill-bg: linear-gradient(
        354.06deg,
        #ff8a00 -84.73%,
        rgba(255, 138, 0, 0) 91.52%
      ),
      #fcdf01;
    --header-mobile-theme-color: #000;
    --header-mobile-theme-outline: #ffc001;
    --header-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25),
      inset 0px -6px 13px rgba(0, 0, 0, 0.25);
    --header-search-bg: transparent;
  }
}

