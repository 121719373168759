.svg-block {
  width: 25px;
  height: 25px;
  margin-inline-end: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
