.theme-dropdown {
  border-radius: 4px;
  .ant-dropdown-menu {
    background: var(--dropdow-bg);
    padding: 0;
  }
  .ant-dropdown-menu-title-content {
    color: var(--classic-color);
    font-size: 14px;
  }
  .ant-dropdown-menu-item {
    padding: 0 25px;
    &:hover {
      background-color: transparent;
    }
    &:not(:last-child) {
      button {
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          opacity: 0.1;
          background: var(--classic-color);
        }
      }
    }
  }
  .ant-dropdown-menu-title-content {
    button {
      padding: 10px 0;
      display: flex;
      align-items: center;
      width: 100%;
      transition: 0.2s;
      position: relative;
      padding-right: 38px;
      img {
        display: block;
        margin-right: 6px;
      }
    }
  }
}

.inputGroup {
  &-label {
    font-size: 16px;
    color: var(--classic-color);
    margin-bottom: 8px;
    line-height: 100%;
    @media screen and (max-width: 500px) {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}

.theme-input {
  background: var(--input-background) !important;
  border-radius: 5px;
  height: 40px;
  overflow: hidden;
  &:not(.no-border) {
    border: 1px solid var(--input-border);
  }
}

.theme-input,
.theme-input input,
.theme-input textarea {
  transition: all 0.2s;
  // transition: none !important;
  box-shadow: none !important;
  color: var(--classic-color);
  font-size: 14px;
  &::placeholder {
    color: var(--input-border);
    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }
  &:focus,
  &:hover {
    border-color: var(--classic-color) !important;
  }
}

.ant-input-prefix {
  margin-right: 10px;
  width: 20px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.theme-input input:-webkit-autofill,
.theme-input input:-webkit-autofill:hover,
.theme-input input:-webkit-autofill:focus,
.theme-input input:-webkit-autofill:active {
  box-shadow: var(--login-input-active-autofill) !important;
  -webkit-box-shadow: var(--login-input-active-autofill) !important;
  -webkit-text-fill-color: var(--sidebar-profile-name);
}
.phone-input {
  box-shadow: var(--login-input-active-autofill) !important;
  -webkit-box-shadow: var(--login-input-active-autofill) !important;
  -webkit-text-fill-color: var(--sidebar-profile-name);
}
.ant-form-item-explain-error {
  font-size: 12px;
}

.input-error {
  color: #ff4d4f;
  font-size: 12px;
  line-height: 1.7;
  margin-top: 3px;
}

.ant-empty-description {
  color: var(--classic-color);
}

.ant-select-selection-placeholder,
.ant-picker-input > input::placeholder {
  font-size: 14px;
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100%;
}

.ant-input {
  border: 0;
  background: none;
}

.ant-select-item-option {
  font-weight: 400 !important;
  background: transparent !important;
  color: var(--classic-color);
  &:hover {
    background: transparent !important;
  }
}

.ant-select-item,
.ant-select-item-empty {
  padding: 13px 12px;
}

.ant-select-dropdown {
  background-color: var(--ant-dropdown-bg);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--classic-color);
}

.ant-select,
.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}
.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-selector {
  border: 0 !important;
}

.profile .ant-picker-suffix {
  margin-right: 0;
}
.register .ant-picker-input {
  //datepicker
  flex-direction: row-reverse;
}
.ant-picker {
  background: var(--input-background) !important;
  box-shadow: none !important;
  border-radius: 5px;
  height: 40px;
  display: flex;
  border: 1px solid var(--input-border);
  &.no-border {
    border: 0 !important;
  }
  .anticon svg {
    width: 18px;
    height: 18px;
    path {
      fill: #a4a4a4;
    }
  }
}
.ant-picker-input > input {
  color: var(--classic-color);
  font-size: 14px;
}
.ant-picker-suffix {
  margin-left: 0;
  margin-right: 15px;
}

.ant-alert {
  padding: 0 5px !important;
}

.ant-select-arrow {
  color: #fff;
}

.ant-switch-checked {
  background-color: var(--theme-color);
}

@media screen and (max-width: 350px) {
  .ant-input-prefix {
    margin-right: 10px;
    width: 15px;
  }
}

// Kristi: as we decided will get rid of antd library, it doesn't give lot of things we need, we will use mui
// this is new input style for mui (20.05)
.mui-textfield {
  width: 100%;
  .MuiInputBase-root {
    background: var(--input-background);
    border-radius: 5px;
    height: 40px;
    overflow: hidden;
    padding: 0 11px;
    color: var(--classic-color);
    font-size: 14px;
    width: 100%;
    &:hover {
      fieldset {
        border-color: rgba(255, 255, 255, 0.3);
      }
    }
    &.Mui-focused {
      fieldset {
        border-color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  input {
    padding: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
  }
  fieldset {
    border-width: 1px !important;
    border-color: var(--input-background);
    transition: 0.2s;
  }
}

.mui-select {
  width: 100%;
  height: 40px;
  background: var(--input-background);
  border-radius: 5px;
  &.disabled {
    .MuiSelect-select {
      opacity: 0.5;
    }
  }
  .MuiSelect-select {
    padding: 0 11px;
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    min-height: initial;
  }
  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
  &.Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.6);
    }
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: var(--input-background);
    transition: 0.2s;
  }
  .MuiSvgIcon-root {
    color: rgba(255, 255, 255, 0.6);
  }
}

.error-message {
  color: rgb(217, 73, 73);
  font-size: 14px;
  margin: 5px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
