.playNowModal {
  .ant-modal-content {
    overflow-y: auto;
  }
  .mailModal-header {
    padding-top: 38px;
  }
  &.full-resize {
    border-radius: 0;
    height: 100vh;
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0;
    top: 0;
    left: 0;
    .ant-modal-content {
      height: 100vh;
      width: 100%;
      margin: 0;
      top: 0;
    }
    .ant-modal-body {
      height: 100%;
    }
  }
  &-actions {
    .theme-modal-close {
      position: static !important;
    }
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    button:first-child {
      margin-right: 10px;
    }
  }
  &-resize {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-body {
    position: relative;
    min-height: 500px;
    @media screen and (max-height: 700px) {
      min-height: auto;
    }
  }
  &-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.overflow-auto {
      overflow: auto;
    }
    iframe {
      height: 100%;
    }
  }
  &-grid {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    overflow: hidden;
  }
  &-rating {
    &-value {
      color: var(--classic-color);
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
    }
  }
  &-error {
    color: var(--classic-color);
    font-size: 20px;
    text-align: center;
    margin: 0;
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-text {
    font-size: 16px;
    color: var(--classic-color);
    margin-top: 40px;
  }
}

@media screen and (max-width: 1320px) {
  .playNowModal-header {
    display: none;
  }
  .playNowModal .mailModal-header {
    background: transparent;
    justify-content: flex-end;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .playNowModal-resize {
    display: none;
  }
  .playNowModal-actions {
    gap: 5px;
    position: static;
  }
.modal-userbalance {
  font-size: 12px;
}
}

@media screen and (max-width: 991px) {
  .playNowModal-error {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .playNowModal {
    .ant-result {
      padding: 30px 20px;
    }
  }
}

.modal-userbalance {
  border-radius: 20px;
  padding: 1px 5px;
  margin-right: 5px;
  background: #fbec00;
  // font-size: 12px;
}
