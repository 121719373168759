.footer {
  background: var(--footer-bg);
  padding: 40px 50px;
  box-shadow: var(--footer-bg-shadow);
  &-payments {
    display: flex;
    align-items: center;
    justify-content: center;
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 25px;
        width: auto;
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
    .divider {
      width: 1px;
      height: 40px;
      background: var(--classic-color);
      margin: 0 33px;
      opacity: 0.1;
    }
  }
  &-ageLimit {
    width: 46px;
    height: 46px;
  }
  &-menu {
    display: grid;
    grid-template-columns: auto auto auto auto 1fr;
    grid-gap: 100px;
    margin: 57px 0 30px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 23px;
      width: 100%;
      text-align: left;
      img {
        display: none;
      }
    }
    &-title {
      font-size: 20px;
      font-weight: 700;
      color: var(--classic-color);
      margin: 0;
      padding-right: 15px;
    }
    &-link {
      color: #717171;
      font-size: 16px;
      transition: 0.2s;
      text-align: left;
      &:hover {
        color: var(--classic-color);
      }
      &-block {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
  &-slogan {
    position: relative;
    margin-bottom: 46px;
    border-radius: 4px;
    overflow: hidden;
    height: 80px;
    width: 100%;
    &-content {
      position: absolute;
	  top: 0;
	  bottom: 0;
	  right: 0;
	  left: 20px;
      display: flex;
      align-items: center;
      color: var(--footer-slogan-color);
    }
    &-title {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &-text {
      font-size: 15px;
    }
    &-logo {
      height: 60px;
      width: auto;
      margin-right: 30px;
    }
  }
  &-text {
    color: #717171;
    font-size: 16px;
    line-height: 120%;
		a {
			color: #fff000;
		}
  }

  .block-mg {
    margin: 25px 0;
  }
}

.custom-hr-line {
  height: 1px;
  background: var(--classic-color);
  opacity: 0.1;
  width: 100%;
}

@media screen and (min-width: 1960px) {
  .footer-menu-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1550px) {
  .footer-menu {
    grid-gap: 75px;
  }
  .footer-menu-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 1390px) {
  .footer {
    padding: 40px 30px;
  }
  .footer-menu {
    grid-gap: 60px;
  }
  .footer-menu-link-block:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1320px) {
  .footer-payments-container img {
    height: 22px;
  }
  .footer-ageLimit {
    width: 40px;
    height: 40px;
  }
  .footer-payments-container img:not(:last-child) {
    margin-right: 30px;
  }
  .footer-payments .divider {
    margin: 0 30px;
  }
}

@media screen and (max-width: 1040px) {
  .footer-menu-title {
    font-size: 14px;
  }
  .footer-menu-header {
    margin-bottom: 15px;
  }
  .footer-menu-link {
    font-size: 12px;
  }
  .footer-menu {
    grid-gap: 50px;
    margin: 28px 0;
  }
  .footer .block-mg {
    margin: 15px 0;
  }
  .footer-payments-container img {
    height: 20px;
  }
  .footer-slogan {
    margin-bottom: 28px;
  }
  .footer-slogan-logo {
    height: 40px;
    margin-right: 15px;
  }
  .footer-slogan-title {
    font-size: 14px;
  }
  .footer-slogan-text {
    font-size: 12px;
  }
  .footer-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .footer-menu {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
  .footer-menu-header {
    img {
      display: block;
    }
  }
  .footer-menu-title {
    font-size: 14px;
  }
  .footer-menu-tab {
    display: none;
    &.active {
      display: block;
    }
  }
  .footer-menu-header {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 850px) {
  .footer-payments-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px 15px;
    justify-items: center;
  }
  .footer-payments-container img:not(:last-child) {
    margin-right: 0;
  }
}

@media screen and (max-width: 700px) {
  .footer {
    padding: 20px 15px;
  }
}



@media screen and (max-width: 550px) {
  .footer-payments-container {
    display: flex;
    grid-gap: 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-payments-container img {
    margin: 5px 7px !important;
    height: 14px;
  }
  .footer-payments .divider {
    margin: 0 20px;
  }
  .footer-ageLimit {
    width: 24px;
    height: 24px;
  }
  .footer-payments .divider {
    height: 30px;
  }
}

@media screen and (max-width: 430px) {
  .footer-slogan-logo {
    height: 25px;
    margin-right: 10px;
  }
  .footer-slogan-title {
    font-size: 12px;
  }
  .footer-slogan-text {
    font-size: 10px;
  }
  .footer-text {
    font-size: 10px;
  }
	.footer-slogan-content {
		left: 15px;
	}
}
