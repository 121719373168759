.paymentCard {
	border-radius: 10px;
	overflow: hidden;
	background: var(--input-border);
	display: flex;
	align-items: center;
	justify-content: center;
	color: #909298;
	position: relative;
	&-bg {
		height: calc(100% - 5px);
		width: calc(100% - 5px);
		background: var(--payment-card-bg);
		border-radius: 10px;
	}
	&.selected {
		background: linear-gradient(270deg, #fbec00, #ff8a00);
		.paymentCard-bg {
			background: var(--cabinet-form-bg);
		}
		.paymentCard-value, .paymentCard-title {
			color: var(--classic-color);
		}
		.paymentCard-logo {
			opacity: 1;
		}
		.paymentCard-selectedIcon {
			opacity: 1;
		}
	}
	&-selectedIcon {
		width: 16px;
		height: 16px;
		top: 15px;
		right: 15px;
		position: absolute;
		opacity: 0;
	}
	&-header {
		padding: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 147px;
		border-bottom: 1px solid var(--input-border);
	}
	&-logo {
		height: 100%;
		width: 100%;
		object-fit: contain;
		opacity: .5;
	}
	&-row {
		padding: 10px 15px;
		text-align: center;
		border-bottom: 1px solid var(--footer-text-clr);
		p {
			margin: 0;
			line-height: 150%;
		}
		&:last-child {
			padding-bottom: 15px;
		}
	}
	&-value {
		font-size: 18px;
	}
	&-label {
		font-size: 16px;
	}
}

@media screen and (max-width: 1700px) {
	.paymentCard-header {
		height: 107px;
	}
}

@media screen and (max-width: 1320px) {
	.paymentCard-value {
		font-size: 16px;
	}
	.paymentCard-label {
		font-size: 14px;
	}
	.paymentCard-header {
		height: 80px;
	}
	.paymentCard-logo {
		height: 65px;

	}
}

@media screen and (max-width: 991px) {
	.paymentCard {
		min-width: 190px;
		flex-basis: 190px;
	}
}

@media screen and (max-width: 700px) {
	.paymentCard-bg {
		width: calc(100% - 3px);
		height: calc(100% - 3px);
	}
	.paymentCard-selectedIcon {
		width: 12px;
		height: 12px;
		top: 10px;
		right: 10px;
	}
}
