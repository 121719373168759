@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

* {
	margin: 0;
	padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Inter", sans-serif;
	width: 100% !important;
}

body {
	max-width: 100%;
	width: 100%;
	margin: 0 auto;
	overflow: auto;
	background-color: var(--sidebar-block-bg);
	overflow-x: hidden;
}
.main-navbar, #main-content {
	// max-width: 1664px;
	width: 100%;
}

button:focus,
input:focus {
  outline: none;
}

button {
	cursor: pointer;
}

img {
	display: block;
}

html {
	scrollbar-color: var(--theme-color) var(--input-background);
	::-webkit-scrollbar {
		width: 7px;
	}
	::-webkit-scrollbar-track {
		background: var(--input-background);
	}
	::-webkit-scrollbar-thumb {
		background: var(--theme-color);
		border-radius: 5px;
	}
}

@media screen and (min-width: 1920px) {
	html {
		background: var(--sidebar-block-bg);
	}
}