$fill-btn-bg: linear-gradient(354.06deg, #FF8A00 -84.73%, rgba(255, 138, 0, 0) 91.52%), #FCDF01;
$fill-btn-color: #000;


button {
  background: transparent;
  border: 0;
  padding: 0;
}

.theme-btn {
  min-height: 42px;
	border-width: 2px;
	border-style: solid;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  padding: 0 30px;
  border-radius: 100px;
	position: relative;
  cursor: pointer;
	&:before {
		border-radius: 100px;
	}
  &.full-width {
    width: 100%;
  }
	&.sqr-btn {
		border-radius: 5px;
		&:before {
			border-radius: 5px;
		}
	}
	@media screen and (max-width: 1320px) {
		font-size: 14px;
	}
	@media screen and (max-width: 700px) {
		&:not(.no-mobile) {
			min-height: 30px;
			padding: 6px 13px;
			font-size: 11px;
		}
	}
}

.theme-btn {
	transition: .2s;
  border-color: var(--theme-color);
  color: var(--footer-slogan-color);
	&.header-btn {
		@media screen and (max-width: 1320px) {
			border-color: var(--header-mobile-theme-outline);
			color: var(--header-mobile-theme-outline);
		}
	}
	&:hover {
		filter: brightness(1.1); 
		box-shadow: var(--button-outline-shadow-hover);
	}
	
	&.fill-btn, &.sqr-btn {
    background: $fill-btn-bg;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			box-shadow: inset 0px -6px 5px rgba(0, 0, 0, 0.25);
		}
    color: $fill-btn-color;
		border: 0;
		&.header-btn {
			@media screen and (max-width: 1320px) {
				background: var(--header-mobile-theme-fill-bg);
				color: var(--header-mobile-theme-color)
			}
		}
  }
	&.fill-btn, &.sqr-btn {
		&:hover {
			box-shadow: var(--button-filled-shadow-hover);
		}	
	}
	
}



