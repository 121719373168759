.mailModal {
	&-header {
		position: relative;
		background: var(--mailModal-header-bg);
		padding: 30px 15px;
		justify-content: center;
		text-align: center;
		.theme-modal-close {
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}
	textarea.ant-input {
		min-height: 160px;
		resize: none;
	}
}
