.profile {
	display: grid;
	grid-template-columns: 1fr 470px;
	grid-gap: 30px;
	&.no-grid {
		display: block;
	}
	&-pd {
		padding: 30px 25px;
	}
	&-settings {
		&-dob {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 10px;
		}
		&-fields {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 30px;
			.cabinet-btn {
				min-width: 100px;
				width: auto;
			}
		}
		&-btns {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 30px;
			margin-top: 28px;
		}
	}
	&-verif {
		&-list {
			padding-left: 0;
			margin-top: 15px;
			list-style: none;
			margin-bottom: 40px;
			li {
				position: relative;
				&:before {
					content: "\2022";  
					color: var(--theme-color);
					display: inline-block; 
					width: 3px; 
					margin-right: 15px;
				}
			}
		}
		&-action {
			display: flex;
			align-items: flex-end;
			.inputGroup {
				min-width: 250px;
				margin-right: 20px;
			}
		}
		&-upload {
			margin-right: 20px;
			display: flex;
			align-items: center;
		}
		&-imgName {
			max-width: 150px;
			width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			flex-shrink: 0;
			flex-grow: 0;
			margin-left: 20px !important;
		}
	}
	&-game {
		&-row {
			display: grid;
			align-items: center;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
		}
		&-fields {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 20px;
		}
	}
}

@media screen and (max-width: 1450px) {
	.profile {
		grid-template-columns: 1fr 380px;
	}
}

@media screen and (max-width: 1320px) {
	.profile {
		grid-template-columns: 1fr 320px;
		&-pd {
			padding: 25px 20px;
		}
	}
}

@media screen and (max-width: 1110px) {
	.profile {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 900px) {
	.profile-verif-action {
		display: grid;
		grid-template-columns: 1fr;
	}
	.profile-verif-action .inputGroup {
		margin-right: 0;
		margin-bottom: 20px;
	}
	.profile-verif-upload {
		margin-right: 0;
		margin-bottom: 20px;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 15px;
		display: grid;
	}
	.profile-verif-imgName {
		max-width: 100%;
		margin-left: 0 !important;
	}
}

@media screen and (max-width: 725px) {
	.profile-settings-fields {
		grid-template-columns: 1fr;
		grid-gap: 15px;
	}
	.profile-game-row,
	.profile-game-fields {
		grid-gap: 15px;
	}
	.profile-settings-btns {
		grid-gap: 15px;
	}
}

@media screen and (max-width: 450px) {
	.profile-game-row {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 420px) {
	.profile-verif-upload {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 400px) {
	.profile-settings-fields {
		.cabinet-blockBtn {
			flex-direction: column;
		}
		.cabinet-btn {
			width: 100%;
		}
	}
	.profile-settings-btns {
		grid-template-columns: 1fr;
	}
}