.bonus {
	&-promo {
		&-btns {
			margin-bottom: 10px;
		}
		&-btn {
			&.active {
				color: var(--theme-color);
			}
			&:not(:last-child) {
				margin-right: 20px;
			}
		}
		&-input {
			width: 200px;
			flex-shrink: 0;
			flex-grow: 0;
		}
		&-row {
			display: flex;
			align-items: flex-end;
			
		}
		&-blockBtn {
			
		}
		&-show {
			display: flex;
			position: relative;
			align-items: flex-end;
			bottom: 3px;
			.inputGroup {
				width: 200px;
				flex-shrink: 0;
				flex-grow: 0;
				margin-right: 20px;
			}
		}
	}
}

@media screen and (max-width: 880px) {
	.bonus-promo-show {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-gap: 15px;
	}
	.bonus-promo-show .inputGroup {
		width: 100%;
		margin-right: 0;
	}
}

@media screen and (max-width: 520px) {
	.bonus-promo-row {
		.cabinet-blockBtn {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			.theme-input {
				width: 100%;
			}
		}
		
	}
	.bonus-promo-show {
		grid-template-columns: 1fr;
	}
}
.cursor-disable {
	cursor: not-allowed;
}

.info-btn {
	background: linear-gradient(268.96deg, #fbec00 -0.85%, #ff8a00 119.75%);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 14px;
	padding: 0 12px;
	height: 40px;
	min-width: 70px;
	width: 100%;
	position: relative;
	transition: 0.2s;
}

.info-mofal {
	background-color: white;
	.ant-modal-body {
		padding: 1rem;
		h1{
			margin: 0;
		}
		p{
			margin: 0;
		}
		.heading {
			font-size: 25px;
			font-weight: 20px;
		}
		.subheading{
			font-size: 14px;
			font-weight: 400;
		}
	}

}