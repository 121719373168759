.cabinet-nav {
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	padding: 19px 30px;
	position: relative;
	display: flex;
	align-items: center;
	background: var(--cabinet-nav-bg);
	margin-bottom: 50px;
	&::before {
		content: '';
		position: absolute;
		background: var(--theme-color);
		top: 0;
		left: 0;
		width: 7px;
		height: 100%;
		border-radius: 20px 0 0 20px;
		box-shadow: 0 0 5px var(--theme-color);
	}
	&-link {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: var(--classic-color);
		font-size: 20px;
		font-weight: 500;
		position: relative;
		transition: none;
		&:not(:last-child) {
			margin-right: 55px;
		}
		&-icon {
			margin-right: 10px;
			display: flex;
			align-items: center;
			img {
				width: 20px;
				height: 20px;
				object-fit: contain;
			}
		}
		&-chevron {
			position: absolute;
			right: 12px;
			top: 0;
			bottom: 0;
			margin: auto 0;
			width: 13px;
			height: 13px;
		}
		@media screen and (min-width: 992px) {
			&:hover, &.active {
				color: var(--theme-color);
				img {
					filter: var(--casino-category-img);
				}
			}
		}
	}
	&-m {
		display: none;
		&-selected {
			background: var(--sidebar-block-bg);
			border-radius: 5px;
			border: 1px solid var(--input-border);
			&.active {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	&-list {
		background: var(--sidebar-block-bg);
		display: none;
		border-left: 1px solid var(--input-border);
		border-right: 1px solid var(--input-border);
		border-bottom: 1px solid var(--input-border);
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		&.active {
			display: block;
		}
	}
}

@media screen and (max-width: 1320px) {
	.cabinet-nav-link {
		font-size: 18px;
	}
	.cabinet-nav-link:not(:last-child) {
		margin-right: 40px;
	}
}

@media screen and (max-width: 991px) {
	.cabinet-nav {
		display: none;
	}
	.cabinet-nav-m {
		display: block;
		margin-bottom: 50px;
	}
	.cabinet-nav-link:not(:last-child) {
		margin-right: 0;
	}
	.cabinet-nav-link {
		padding: 9px 12px;
		font-size: 16px;
	}
	.cabinet-nav-link-icon {
		img {
			width: 18px;
			height: 18px;
		}
	}
}