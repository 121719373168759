.main-navbar {
  position: sticky;
  touch-action: none;
  background: var(--header-bg);
  width: 100%;
  height: 70px;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--header-shadow);
  &-responsiveMenu {
    display: none;
  }
  &-right {
    display: flex;
    align-items: center;
  }
  &-btns {
    display: grid;
    grid-template-columns: 164px;
    grid-gap: 20px;
  }
  .divider {
    width: 1px;
    height: 40px;
    margin: 0 20px;
    background: #717171;
  }
}

#navbar-lang-container,
#navbar-langM-container {
  position: relative;
  display: flex;
  align-items: center;
  .ant-select-selector {
    border: 0 !important;
  }
}

.navbar-lang {
  .ant-select-selector {
    padding: 0 !important;
    height: 42px !important;
    .navbar-lang-option {
      margin-right: 0 !important;
      width: 42px;
      height: 42px;
      object-fit: cover;
    }
  }
  .ant-select-selection-item {
    width: 42px;
    height: 42px;
    color: transparent !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 42px;
    height: 42px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-option {
    margin-right: 10px;
    border-radius: 50%;
  }
  &-dropdown {
    width: 220px !important;
    width: fit-content;
    border-radius: 4px;
    left: initial !important;
    right: 0;
    .ant-select-item {
      &:not(:last-child) {
        .ant-select-item-option-content {
          &:before {
            content: "";
            position: absolute;
            bottom: -13px;
            left: 0;
            opacity: 0.1;
            background: var(--classic-color);
            width: 100%;
            height: 1px;
          }
        }
      }
    }
    .rc-virtual-list-scrollbar-thumb {
      background: var(--theme-color) !important;
      border-radius: 5px !important;
    }
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      position: relative;
      overflow: initial;
      padding-right: 34px;
    }
  }
}

.navbar-theme {
  width: 45px;
  height: 45px;
  background: var(--switch-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  transition: 0.2s;
  img {
    width: 46%;
    height: 46%;
  }
  &:hover {
    box-shadow: var(--button-filled-shadow-hover);
    transition: 0.2s;
  }
  &.dark-theme {
    @media screen and (max-width: 1320px) {
      box-shadow: inset 0px -3px 4px rgba(255, 255, 255, 0.15);
      img {
        filter: invert(95%) sepia(40%) saturate(7453%) hue-rotate(348deg) brightness(94%) contrast(110%);
      }
    }
  }
}

.navbar-help {
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  width: 42px;
  height: 42px;
  img {
    width: 100%;
    height: 100%;
  }
}

.navbar-search {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 365px;
  width: 100%;
  &-icon {
    position: absolute;
    top: 0;
    left: 18px;
    bottom: 0;
    margin: auto 0;
    z-index: 1;
  }
  input {
    display: flex;
    background: var(--header-search-bg);
    height: 45px;
    padding: 0 13px 0 50px;
    width: 100%;
    border-radius: 38px;
    border: 1px solid #717171;
    cursor: pointer;
    color: var(--classic-color);
    font-size: 14px;
    &::placeholder {
      color: #717171;
    }
  }

  &-res {
    position: absolute;
    width: 650px;
    padding: 30px 30px 30px 50px;
    background: var(--dropdow-bg);
    border: 1px solid var(--navbar-search-border);
    border-radius: 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    left: 0;
    top: calc(100% + 15px);
    text-align: left;
    cursor: initial;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }
  &-title {
    margin-bottom: 30px;
    color: var(--classic-color);
    font-size: 20px;
    text-transform: uppercase;
  }
  &-games {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    &.no-grid {
      display: block;
    }
    .gameCard {
      display: grid;
      grid-template-rows: 90px 1fr;
      align-items: center;
    }
    .gameCard-name {
      margin: 15px 0;
    }
    .gameCard-img {
      height: 100%;
      img {
        object-fit: cover;
      }
    }
  }
  &-game {
    &_img {
      display: flex;
      align-items: center;
    }
    &_name {
      color: var(--classic-color);
      font-size: 10px;
      text-align: center;
      margin-top: 6px;
      line-height: 130%;
    }
  }
  &-link {
    display: flex;
    align-items: center;
    color: var(--classic-color);
    font-size: 20px;
    text-decoration: none;
    transition: 0.2s;
    padding: 5px;
    opacity: 0.7;
    img {
      margin-right: 12px;
    }
    &:hover {
      opacity: 1;
      color: var(--classic-color);
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    background: var(--classic-color);
    margin: 34px 0;
  }
  &-left {
    display: none;
    align-items: center;
  }
}

.navbar-logo {
  &-t {
    height: 30px;
    width: auto;
    display: flex;
    align-items: center;
    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }
}

.navbar-time {
  display: flex;
  align-items: center;
  margin-top: 30px;
  img {
    margin-right: 10px;
  }
  p {
    color: var(--sidebar-link-color);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.navbar-depositBtn {
  margin-right: 20px;
}

.navbar-email {
  border-radius: 39px;
  border: 2px solid #fcdf01;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 39px;
  margin-right: 20px;
}

.navbar-profile {
  display: flex;
  align-items: center;
  // margin-right: 20px;
  width: 42px;
  height: 42px;
  img {
    width: 100%;
    height: 100%;
  }
  &-t {
    // margin-right: 20px;
    display: flex;
    align-items: center;
    &-user {
      margin-right: 16px;
      text-align: right;
    }
    &-name {
      font-size: 14px;
      line-height: 1;
      color: #1c1c1c;
      font-weight: 600;
    }
    &-balance {
      font-size: 12px;
      color: var(--navbar-profile-balance);
      margin-top: 3px;
      line-height: 1;
      font-weight: 600;
    }
    &-btn {
      width: 30px;
      height: 30px;
      text-transform: uppercase;
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--navbar-profile-btn-bg);
      border: 1px solid #a4a4a4;
      box-shadow: var(--navbar-profile-btn-shadow);
      border-radius: 5px;
    }
  }
}

.navbar-mobileBtn {
  display: none;
  div {
    width: 30px;
    height: 5px;
    background: var(--navbar-mobile-btn);
    border-radius: 41px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

@media screen and (max-width: 1580px) {
  .navbar-theme,
  .navbar-profile,
  .navbar-lang .ant-select-selection-item,
  .navbar-lang .ant-select-selector .navbar-lang-option,
  .navbar-help {
    width: 35px;
    height: 35px;
  }
  .navbar-lang .ant-select-selector {
    height: 35px !important;
  }
  .navbar-email {
    padding: 10px 25px;
  }
  .navbar-time p {
    font-size: 14px;
  }
  .main-navbar-right .divider {
    height: 38px;
    margin: 0 15px;
  }
  .main-navbar-btns {
    grid-gap: 15px;
  }
  .navbar-email,
  .navbar-depositBtn {
    margin-right: 15px;
  }
  .navbar-search {
    max-width: 250px;
  }
}

@media screen and (max-width: 1320px) {
  .main-navbar {
    height: 60px;
    width: 100%;
  }
  .main-navbar-right .divider,
  .main-navbar-right #navbar-lang-container,
  .main-navbar-right .navbar-email {
    display: none;
  }
  .navbar-logo-t {
    display: flex;
  }
  .main-navbar-btns {
    grid-gap: 10px;
    // margin-right: 10px;
    grid-template-columns: repeat(1, 1fr);
    .theme-btn {
      padding: 0 10px;
      min-width: 130px;
    }
  }
  .main-navbar-left {
    display: flex;
    align-items: center;
  }
  .main-navbar {
    .divider {
      height: 26px;
      margin: 0 16px;
    }
  }
  .navbar-mobileBtn {
    display: block;
  }
  .navbar-profile {
    display: none;
  }
  .navbar-search-link {
    font-size: 14px;
  }
  .navbar-search-link img {
    width: 20px;
    height: auto;
  }
  .navbar-search-title {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .navbar-help {
    width: 40px;
    height: 40px;
    opacity: 1;
  }
  .navbar-email {
    width: 40px;
    height: 40px;
    margin-right: 0;
    border: 1px solid #717171;
    padding: 0;
    border-radius: 50%;
    img {
      width: 50%;
      height: 50%;
      opacity: 0.4;
    }
  }
}

@media screen and (max-width: 700px) {
  .main-navbar-btns {
    margin-right: 0;
    .theme-btn {
      // min-width: auto;
      min-width: 90px;
    }
  }
  .navbar-mobileBtn div {
    height: 3px;
    width: 21px;
  }
  .navbar-mobileBtn div:not(:last-child) {
    margin-bottom: 3px;
  }
  .main-navbar .divider {
    height: 17px;
  }
  .main-navbar-left {
    align-items: center;
  }
  .navbar-logo-t {
    height: 20px;
  }
  .main-navbar {
    height: 50px;
  }
  .main-navbar-right {
    .navbar-theme {
      display: none;
    }
  }
  .main-navbar .divider {
    margin: 0 10px;
  }
  .navbar-profile-t-user {
    margin-right: 10px;
    .navbar-profile-t-name {
      display: none;
    }
  }
  .navbar-profile-t {
    margin-right: 0;
  }
  .navbar-profile-t-balance {
    margin-top: 0;
  }
}

@media screen and (max-width: 420px) {
  .navbar-logo-t {
    width: 60px;
  }
}

@media screen and (max-width: 380px) {
  .navbar-search input {
    padding-left: 34px;
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
  .navbar-search-icon {
    left: 12px;
  }
}

@media screen and (max-width: 360px) {
  .navbar-logo-t-header {
    height: 16px;
  }
  .main-navbar .divider {
    margin: 0 7px;
  }
  .main-navbar-btns {
    grid-gap: 6px;
    .theme-btn {
      font-size: 10px;
    }
  }
}
