@font-face {
	font-family: 'Grantmouth Standard';
	src: url('./fonts/GrantmouthStandard.woff2') format('woff2'),
			 url('./fonts/GrantmouthStandard.woff') format('woff'),
			 url('./fonts/GrantmouthStandard.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
$numFont: 'Grantmouth Standard', sans-serif;


.gameCard {
	position: relative;
	height: 100%;
	&-img {
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			display: block;
			transition: .4s;
		}
	}
	&-name {
		color: var(--classic-color);
		font-size: 14px;
		line-height: 130%;
		text-align: center;
		position: relative;
		margin-top: 30px;
		margin-bottom: 20px;
		word-break: break-word;
	}
	&.is-round {
		.gameCard-name {
			margin-top: 0;
		}
	}
	.glow {
		position: absolute;
		bottom: 0;
		width: 100%;
		max-width: 60%;
		object-fit: scale-down;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	&-num {
		position: absolute;
		font-family: $numFont;
		color: #000;
		font-size: 150px;
		width: 58px;
		top: -57px;
		height: 150px;
		left: 0;
		text-shadow: 0px -1px 13px var(--game-card-num-shadow);
		-webkit-text-stroke: 2px var(--game-card-num-shadow);
		margin: 0;
		transition: .4s;
	}
}

.gameCard.is-round {
	.gameCard-img img {
		transform: scale(0.98);
	}
	&:hover {
		.gameCard-img img {
			transform: scale(1);
		}
		.gameCard-num {
			transform: scale(1);
		}
	}
}

.term-terms-title {
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 20px;
}