.banner {
  border-radius: 8px;
	max-width: 100%;
	position: relative;
	margin: 20px 0 50px;
	overflow: hidden;
	display: inline-block;
	width: 100%;
	&-slide {
		position: relative;
		color: var(--classic-color);
		&-title {
			font-size: 1.2vw;
			font-weight: 700;
			margin-bottom: 0.9vw;
		}
		&-text {
			font-size: 0.75vw;
			line-height: 130%;
			margin-bottom: 0.9vw;
		}
		&-img {
			display: block;
			width: 100%;
			height: 17.5vw;
			min-height: 17.5vw;
			object-fit: cover;
			object-position: center;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: right;
			}
		}
		&-block {
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
		}
		&-content {
			background: var(--banner-bg-content);
			border-radius: 15px;
			margin-left: 3vw;
			padding: 1.5vw 2vw;
			max-width: 20vw;
			width: 100%;
			text-align: left;
		}
		&-btn {
			background: linear-gradient(360deg, #FF8A00 -196.43%, rgba(255, 138, 0, 0) 100%), #FBE301;
			border-radius: 5px;
			color: #000;
			padding: 0.4vw 0.8vw;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.7vw;
			transition: .2s;
			&:hover {
				box-shadow: 0px 0px 11px rgba(255, 250, 174, 0.65), inset 0px 0px 7px rgba(255, 138, 0, 0.94), inset 0px 0px 20px #FFFAAE;
			}
		}
	}
	
}


.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
.swiper-pagination {
	position: absolute;
	z-index: 2;
	bottom: 10px;
	background: #171717;
	left: 0;
	right: 0;
	margin: 0 auto;
	padding: 4px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.swiper-pagination-bullet {
  margin: 0 5px;
  cursor: pointer;
  transition: opacity 0.25s ease-in;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
  background: #fff;
	opacity: .3;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
	&.swiper-pagination-bullet-active {
		opacity: 1;
	}
}

@media screen and (max-width: 1404px) {
	.banner-slide-title {
		font-size: 20px;
	}
	.banner-slide-title, .banner-slide-text {
		margin-bottom: 15px;
	}
	.banner-slide-text {
		font-size: 14px;
	}
	.banner-slide-content {
		max-width: 370px;
		margin-left: 50px;
		padding: 20px 25px;
	}
	.banner-slide-btn {
		padding: 5px 15px;
		font-size: 12px;
	}
}

@media screen and (max-width: 1320px) {
	.banner {
		margin-top: 0;
		border-radius: 0;
	}
	.banner-slide-img {
		height: 270px;
		min-height: 270px;
	}
	.banner-px {
		padding: 0;
	}
	.banner-slide-title {
		font-size: 20px;
		margin-bottom: 17px;
	}
	.banner-slide-text {
		font-size: 16px;
		margin-bottom: 17px;
	}
	.banner-slide-btn {
		padding: 8px 15px;
		font-size: 13px;
	}
}

// @media screen and (max-width: 1000px) {
// 	.banner-slide-img {
// 		img {
// 			object-position: right;
// 		}
// 	}
// }

@media screen and (max-width: 740px) {
	.banner-slide-img {
		height: 300px;
		min-height: 300px;
	}
	.banner-slide-img img {
		object-position: center;
	}
}

@media screen and (max-width: 600px) {
	.banner-slide-block {
		width: 100%;
		height: 100%;
		background: var(--banner-bg-slide-m);
		padding: 15px;
	}
	.banner-slide-content {
		background: transparent;
		padding: 0;
		margin-left: 0;
	}
}

@media screen and (max-width: 400px) {
	.banner-slide-title {
		font-size: 16px;
	}
	.banner-slide-text {
		font-size: 12px;
	}
}
