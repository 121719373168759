.support {
  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
  &-faq {
    border-radius: 10px;
    overflow: hidden;
    &-block {
      background: var(--sidebar-block-bg);
      padding: 30px;
    }
    &-header {
      display: flex;
      align-items: center;
      background: var(--support-block-bg);
      padding: 30px;
      p {
        font-size: 24px;
        color: var(--classic-color);
        font-weight: 700;
        margin: 0;
      }
      img {
        margin-right: 10px;
      }
    }
    &-quest {
      display: flex;
      width: 100%;
      text-align: left;
      align-items: baseline;
      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
        transform: rotate(0deg);
        position: relative;
        top: 1px;
        &.active {
          transform: rotate(45deg);
        }
      }
      p {
        margin: 0;
        font-size: 16px;
        color: var(--classic-color);
        font-weight: 600;
      }
    }
    &-answ {
      color: #898b90;
      font-size: 14px;
      margin-top: 15px;
      padding-left: 31px;
      display: none;
      &.active {
        display: block;
      }
    }
    &-divider {
      background: linear-gradient(
        54deg,
        var(--theme-color) 0%,
        rgba(2, 0, 36, 0) 100%
      );
      width: 100%;
      height: 1px;
    }
  }
  &-contact {
    border-radius: 10px;
    overflow: hidden;
    &-title {
      font-size: 20px;
      color: var(--classic-color);
      text-transform: uppercase;
      margin-bottom: 30px;
      font-weight: 700;
    }
    &-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      max-width: 600px;
      align-items: center;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &-icon {
        display: flex;
        align-items: center;
        img {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }
      }
      &-title {
        font-size: 16px;
        margin: 0;
        color: var(--classic-color);
      }
      &-text {
        color: #808185;
        font-size: 16px;
        text-decoration: none;
        &:hover {
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .support-faq-header p {
    font-size: 20px;
  }
  .support-faq-header img {
    width: 20px;
    height: 20px;
  }
  .support-contact-title {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1030px) {
  .support-faq-header,
  .support-faq-block {
    padding: 20px;
  }
  .support-faq-header p,
  .support-contact-title {
    font-size: 17px;
  }
}

@media screen and (max-width: 870px) {
  .support-grid {
    grid-template-columns: 1fr;
  }
  .support-contact-group {
    grid-template-columns: 1fr 2fr;
  }
}

@media screen and (max-width: 484px) {
  .support-contact-group {
    max-width: initial;
    grid-template-columns: 1fr;
    grid-gap: 6px;
  }
  .support-contact-group-text {
    padding-left: 46px;
  }
}
