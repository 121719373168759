.content-px {
	padding-left: 30px;
	padding-right: 30px;
	@media screen and (max-width: 700px) {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.content-py {
	padding-top: 50px;
	padding-bottom: 50px;
	@media screen and (max-width: 700px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.page-layout {
	padding: 15px 0 30px;
}

.no-pointer {
	cursor: initial;
}

.on-desktop {
	@media screen and (max-width: 1320px) {
		display: none !important;
	}
}

.on-tablet {
	@media screen and (min-width: 1321px) {
		display: none !important;
	}
}

.on-mobile {
	@media screen and (min-width: 701px) {
		display: none !important;
	}
}

.chevron-left {
	transform: scale(-1, 1);
}

.layout {
	display: grid;
	grid-template-columns: 256px calc(100% - 256px);
	width: 100%;
	margin: 0 auto;
	&-content {
		min-height: 100vh;
		display: grid;
		grid-template-rows: auto 1fr auto;
		// overflow-x: hidden;
		width: 100%;
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		background-color: var(--sidebar-block-bg);

		/* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}
	}
	&-title {
		font-size: 50px;
		color: var(--classic-color);
		margin-bottom: 30px;
		font-weight: 600;
		@media screen and (max-width: 700px) {
			font-size: 40px;
			margin-bottom: 25px;
		}
		@media screen and (max-width: 480px) {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1320px) {
		grid-template-columns: 1fr;
	}
}

.uw-content {
	max-width: 1740px;
	margin: 0 auto;
	width: 100%;
}

.black-image {
	filter: brightness(0);
}

.no-results {
	font-size: 16px;
	color: var(--classic-color);
	margin: 0;
	@media screen and (max-width: 800px) {
		font-size: 14px;
	}
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.t-center {
	text-align: center;
}

.ant-spin {
	color: var(--theme-color);
}
.ant-spin-dot-item {
	background-color: var(--theme-color);
}

#main-content {
	background-color: var(--sidebar-block-bg);
	overflow: hidden;
}



.casino-scroll::-webkit-scrollbar,
.game-body::-webkit-scrollbar {
  width: 5px;
}

.casino-scroll-horizontal::-webkit-scrollbar-thumb:horizontal{
	background: #161616;
	border-radius: 20px;
}

.casino-scroll-horizontal::-webkit-scrollbar {
	height: 4px;
}

.casino-scroll-horizontal::-webkit-scrollbar-track {
	border-radius: 3px;
  background-color: var(--navbar-search-border);
}

.casino-scroll::-webkit-scrollbar-track,
.game-body::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #5c5200;
}

.casino-scroll::-webkit-scrollbar-thumb,
.game-body::-webkit-scrollbar-thumb {
  background-color: #fcdf01;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

.iframe-page {
	background: #191e29;
}

.errorPage {
	padding: 0;
	.ant-result-title, .ant-result-subtitle {
		color: var(--classic-color);
	}
	.ant-result-icon span {
		color: var(--theme-color) !important;
	}
}
